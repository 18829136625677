import React from 'react';

import { container, grid, wrapper } from './pricing.module.scss';

import { ISection } from '../../models/section.model';
import { IProductVariant } from '../../models/product-variant.model';

import Section from '../hoc/section';
import PricingCard from '../organisms/pricing-card';

interface IPricingSection extends ISection {
    items: {
        variantLeft: IProductVariant;
        variantMiddle: IProductVariant;
        variantRight: IProductVariant;
    };
}

interface IPricingProps {
    section: IPricingSection;
    className?: string;
}

const Pricing: React.FC<IPricingProps> = ({ section }) => {
    const { items } = section;

    return (
        <Section className={container} classes={{ container: grid }}>
            <div className={wrapper}>
                <PricingCard variant={items.variantLeft} side={'left'} />
                <PricingCard variant={items.variantMiddle} side={'middle'} />
                <PricingCard variant={items.variantRight} side={'right'} />
            </div>
        </Section>
    );
};

export default Pricing;
