// extracted by mini-css-extract-plugin
export var backgroundAnimation = "pricing-card-module--backgroundAnimation--08257";
export var button = "pricing-card-module--button--6784b";
export var container = "pricing-card-module--container--bd553";
export var featured = "pricing-card-module--featured--38a64";
export var featuredLabel = "pricing-card-module--featured-label--276bc";
export var header = "pricing-card-module--header--4615d";
export var name = "pricing-card-module--name--c2d66";
export var perk = "pricing-card-module--perk--4e57a";
export var perks = "pricing-card-module--perks--6a199";
export var price = "pricing-card-module--price--e34dd";