import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    container,
    featured,
    header,
    name,
    price,
    perks,
    button,
    featuredLabel,
    perk,
} from './pricing-card.module.scss';
import { IProductVariant } from '../../models/product-variant.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { IClient, IClientPackage } from '../../models/client.model';
import { IOrder } from '../../models/order.model';
import useTranslations from '../../hooks/use-translations';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { useClient } from '../../hooks/use-client';
import { useModal } from '../../hooks/use-modal';
import { useOrder } from '../../hooks/use-order';

import Button from '../atoms/button';
import { useCandidate } from '../../hooks/use-candidate';

type TSide = 'left' | 'middle' | 'right';

interface IPricingCardProps {
    variant: IProductVariant;
    side: TSide;
    className?: string;
}

interface IPricingQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale'>>;
}

const PricingCard: React.FC<IPricingCardProps> = ({ className = '', variant, side }) => {
    const t = useTranslations('PricingCard');
    const { allPage } = useStaticQuery<IPricingQueryResult>(query);
    const { addModal } = useModal();
    const orderPagePathname = usePagePathname(allPage, 'client-order-create');
    const candidate = useCandidate();
    const client = useClient();
    const order = useOrder({ queries: ['list'] });

    const hasActiveOrders = getUserHasActiveOrders(order.list.data);
    const clientHighestPackage = getClientHighestPackage(client.data);
    const canBuy = getCanBuy({
        clientHighestPackage,
        variant,
        hasActiveOrders,
        isCandidate: candidate.isLoggedIn,
    });

    const handleClientCantBuy = () => {
        if (candidate.isLoggedIn) {
            addModal({
                modalKey: 'common-modal',
                modalProps: {
                    title: t.modalCandidateTitle,
                    children: t.modalCandidateContent,
                    confirmText: t.modalCandidateConfirm,
                    actions: ['confirm'],
                },
            });
            return;
        }
        if (hasActiveOrders) {
            addModal({ modalKey: 'client-has-active-order-modal' });
            return;
        }
        addModal({
            modalKey: 'common-modal',
            modalProps: {
                title: t.modalHigherPackageTitle,
                confirmText: t.modalHigherPackageConfirm,
                children: t.modalHigherPackageContent,
                actions: ['confirm'],
            },
        });
    };

    if (!variant) return null;

    return (
        <div className={`${className} ${container} ${side === 'middle' ? featured : ''}`}>
            {side === 'middle' && <span className={featuredLabel}>{t.featured}</span>}
            <div className={header}>
                <p className={name}>{variant.name}</p>
                <p className={price}>{variant.finalPrice.grossDisplay}</p>
            </div>
            <div className={perks}>
                {side === 'left' && (
                    <>
                        <p className={perk}>{t.s['1']}</p>
                        <p className={perk}>{t.s['2']}</p>
                        <p className={perk}>{t.s['3']}</p>
                        <p className={perk}>{t.s['4']}</p>
                        <p className={perk}>{t.s['5']}</p>
                        <p className={perk}>{t.s['6']}</p>
                        <p className={perk}>{t.s['7']}</p>
                        <p className={perk}>{t.s['8']}</p>
                        <p className={perk}>{t.s['9']}</p>
                    </>
                )}
                {side === 'middle' && (
                    <>
                        <p className={perk}>{t.m['1']}</p>
                        <p className={perk}>{t.m['2']}</p>
                        <p className={perk}>{t.m['3']}</p>
                        <p className={perk}>{t.m['4']}</p>
                        <p className={perk}>{t.m['5']}</p>
                        <p className={perk}>{t.m['6']}</p>
                        <p className={perk}>{t.m['7']}</p>
                        <p className={perk}>{t.m['8']}</p>
                        <p className={perk}>{t.m['9']}</p>
                        <p className={perk}>{t.m['10']}</p>
                        <p className={perk}>{t.m['11']}</p>
                        <p className={perk}>{t.m['12']}</p>
                    </>
                )}
                {side === 'right' && (
                    <>
                        <p className={perk}>{t.l['1']}</p>
                        <p className={perk}>{t.l['2']}</p>
                        <p className={perk}>{t.l['3']}</p>
                        <p className={perk}>{t.l['4']}</p>
                        <p className={perk}>{t.l['5']}</p>
                        <p className={perk}>{t.l['6']}</p>
                        <p className={perk}>{t.l['7']}</p>
                        <p className={perk}>{t.l['8']}</p>
                        <p className={perk}>{t.l['9']}</p>
                        <p className={perk}>{t.l['10']}</p>
                        <p className={perk}>{t.l['11']}</p>
                        <p className={perk}>{t.l['12']}</p>
                        <p className={perk}>{t.l['13']}</p>
                        <p className={perk}>{t.l['14']}</p>
                        <p className={perk}>{t.l['15']}</p>
                        <p className={perk}>{t.l['16']}</p>
                    </>
                )}
            </div>
            {canBuy ? (
                <Button
                    className={button}
                    stylePreset={'cta'}
                    as={'link'}
                    to={`${orderPagePathname}?variantId=${variant.variantId}`}
                    isDisabled={order.list.isFetching}
                >
                    {t.button}
                </Button>
            ) : (
                <Button
                    className={button}
                    stylePreset={'cta'}
                    onClick={handleClientCantBuy}
                    isDisabled={order.list.isFetching}
                >
                    {t.button}
                </Button>
            )}
        </div>
    );
};

function getUserHasActiveOrders(orders: IOrder[] | undefined) {
    if (!orders || !orders.length) return false;
    return orders.some((order) => order.payments[0]?.canRetry);
}

interface IGetCanBuyConfig {
    clientHighestPackage: IClientPackage | undefined;
    variant: IProductVariant | null | undefined;
    hasActiveOrders: boolean | undefined;
    isCandidate: boolean;
}

function getCanBuy({
    clientHighestPackage,
    variant,
    hasActiveOrders,
    isCandidate,
}: IGetCanBuyConfig) {
    if (hasActiveOrders || isCandidate) return false;
    if (!variant) return true;
    return !clientHighestPackage || clientHighestPackage.businessMeaning <= variant.businessMeaning;
}

function getClientHighestPackage(client: IClient | undefined | null) {
    if (!client || !client.packages.length) return;
    return client.packages.reduce((prev, current) =>
        prev.businessMeaning > current.businessMeaning ? prev : current
    );
}

export const query = graphql`
    query {
        allPage(filter: { type: { in: ["client-order-create", "client-orders"] } }) {
            edges {
                node {
                    pathname
                    type
                    locale
                }
            }
        }
    }
`;

export default PricingCard;
